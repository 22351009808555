import React, { Component, type ReactElement } from 'react'
import images from '../images'
import type IHeaderHandler from './HeaderHandler/IHeaderHandler'
import './style.scss'

interface Props {
  handler: IHeaderHandler
}

class Header extends Component<Props> {
  componentDidMount(): void {
    document.addEventListener('wheel', this.props.handler.handleNavBarOnScroll)
    document.addEventListener('scroll', this.props.handler.handleNavBarOnScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.props.handler.handleNavBarOnScroll)
    document.removeEventListener('scroll', this.props.handler.handleNavBarOnScroll)
  }

  render(): ReactElement {
    const { handler } = this.props

    return (
      <header className='header' id='header'>
        <div className='footerBackground'></div>
        <div className='headerBackground'></div>
        <nav
          className={handler.shouldShowBlackLogo ? 'header-darkNavigation' : 'header-navigation'}
        >
          <a
            className='header-blackLogoLink'
            href='/'
            referrerPolicy='no-referrer'
            style={{ display: `${handler.shouldShowBlackLogo ? 'block' : 'none'}` }}
          >
            <img alt='logo' className='header-logo' src={images.blackLogo} />
          </a>
          <a
            className='header-logoLink'
            href='/'
            referrerPolicy='no-referrer'
            style={{ display: `${handler.shouldShowBlackLogo ? 'none' : 'block'}` }}
          >
            <img alt='logo' className='header-logo' src={images.logo} />
          </a>
          {/* <a href='/message' referrerPolicy='no-referrer'>
            <span className="nav-eng">MESSAGE</span>
            トップメッセージ
          </a> */}
          <a href='/company' referrerPolicy='no-referrer'>
            <span className='nav-eng'>COMPANY</span>
            会社概要
          </a>
          <a href='/news' referrerPolicy='no-referrer'>
            <span className='nav-eng'>NEWS</span>
            ニュース
          </a>
          <a href='/culture' referrerPolicy='no-referrer'>
            <span className='nav-eng'>CULTURE</span>
            カルチャー
          </a>
          <a href='/careers' referrerPolicy='no-referrer'>
            <span className='nav-eng'>CAREERS</span>
            採用情報
          </a>
          <a href='/mediakit' referrerPolicy='no-referrer'>
            <span className='nav-eng'>MEDIA KIT</span>
            メディアキット
          </a>
          <a href='/contact' referrerPolicy='no-referrer'>
            <span className='nav-eng'>CONTACT</span>
            お問い合わせ
          </a>
        </nav>

        <div className='fakeBustersLink'>
          <a
            className='fakeBustersLink-link'
            href='https://www.fakebusters-iva.com/ja'
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noreferrer'
            style={{ display: `${handler.shouldShowBlackLogo ? 'none' : 'block'}` }}
          >
            <img
              alt='fake-busters-logo-link'
              src={images.fakebustersLink}
              onMouseOver={() => this.handleMouseEvent('white_hover')}
              onMouseOut={() => this.handleMouseEvent('white')}
            />
          </a>

          <a
            className='black-fakeBustersLink-link'
            href='https://www.fakebusters-iva.com/ja'
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noreferrer'
            style={{ display: `${handler.shouldShowBlackLogo ? 'block' : 'none'}` }}
          >
            <img
              alt='fake-busters-logo-link'
              src={images.fakebustersLinkBlack}
              onMouseOver={() => this.handleMouseEvent('black_hover')}
              onMouseOut={() => this.handleMouseEvent('black')}
            />
          </a>
        </div>
      </header>
    )
  }

  private readonly handleMouseEvent = (type: string): void => {
    const logo = document.querySelector('.fakeBustersLink-link img') as HTMLImageElement
    const blackLogo = document.querySelector('.black-fakeBustersLink-link img') as HTMLImageElement

    if (type === 'white') {
      logo.src = images.fakebustersLink
    } else if (type === 'white_hover') {
      logo.src = images.fakebustersLinkHover
    }

    if (type === 'black') {
      blackLogo.src = images.fakebustersLinkBlack
    } else if (type === 'black_hover') {
      blackLogo.src = images.fakebustersLinkBlackHover
    }
  }
}

export default Header
