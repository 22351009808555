import React, { Component, type ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import Message from './Routes/Message/Message'
import Careers from './Routes/Careers/Careers'
import Culture from './Routes/Culture/Culture'
import Company from './Routes/Company/Company'
import ContactUs from './Routes/ContactUs/ContactUs'
import Home from './Routes/Home/Home'
import News from './Routes/News/News'
import Mediakit from './Routes/Mediakit/Mediakit'
import PageNotFound from './Routes/PageNotFound/PageNotFound'
import InfoPolicy from './Routes/InfoPolicy/InfoPolicy'
import PrivacyPolicy from './Routes/PrivacyPolicy/PrivacyPolicy'
import SocialMedia from './Routes/SocialMedia/SocialMedia'
import TermsAndConditions from './Routes/TermsAndConditions/TermsAndConditions'
import ContactUsDone from './Routes/ContactUs/ContactUsDone'
import ContactUsConfirm from './Routes/ContactUs/ContactUsConfirm'
import NewsDetails from './Routes/News/NewsDetails/NewsDetails'
import NewsPresenterFactory from './Routes/News/PresentersFactory/NewsPresentersFactory'
import './common/theme.css'
import './App.css'
import type INewsPresentersFactory from './Routes/News/PresentersFactory/INewsPresentersFactory'

class App extends Component {
  render(): ReactElement {
    const newsFactory: INewsPresentersFactory = new NewsPresenterFactory()

    return (
      <Routes>
        <Route
          path='/'
          Component={() => {
            return <Home newsPresenter={newsFactory.getNewsPresenter()} />
          }}
        />
        <Route path='/message' Component={Message} />
        <Route path='/careers' Component={Careers} />
        <Route path='/culture' Component={Culture} />
        <Route path='/company' Component={Company} />
        <Route path='/contact' Component={ContactUs} />
        <Route path='/contactdone' Component={ContactUsDone} />
        <Route path='/contactconfirm' Component={ContactUsConfirm} />
        <Route path='/mediakit' Component={Mediakit} />
        <Route
          path='/news'
          Component={() => {
            return <News presenter={newsFactory.getNewsPresenter()} />
          }}
        />
        <Route
          path='/newsdetails'
          Component={() => {
            return (
              <NewsDetails
                factory={newsFactory}
                presenter={newsFactory.getNewsDetailsPresenter()}
              />
            )
          }}
        />
        <Route path='/infopolicy' Component={InfoPolicy} />
        <Route path='/privacypolicy' Component={PrivacyPolicy} />
        <Route path='/socialmedia' Component={SocialMedia} />
        <Route path='/termsandconditions' Component={TermsAndConditions} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    )
  }
}

export default App
